<template>
  <VCard class="mb-3">
    <div class="d-flex align-center justify-content-space-between">
      <VCol cols="auto" class="text-left pl-7">
        <h3 class="text-h4">{{ $t('menu.ns_codes') }}</h3>
      </VCol>
      <VCol cols="auto" class="text-right">
        <VBtn
          class="ml-4 success-bg text-white text-transform-none br-10"
          @click="createDialog = true"
          v-if="permissions.can_create_ns_code"
        >
          <VIcon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.smAndDown">
            {{ $t("btn.create") }}
          </div>
        </VBtn>
      </VCol>
    </div>

    <VDivider class="mt-3" />

    <v-simple-table class="my-table" :dense="!isLarge" :height="getTableHeight">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="xs"></th>
          <th class="text-left sm">
            <div class="header" style="align-items: flex-start !important;">
              <VTextField
                v-on:keypress="validationForTitle($event)"
                @paste="validationForTitle($event)"
                dense
                hide-details
                solo
                v-model="filterProps.name"
                @input="onScanerField"
                :placeholder="$t('nsCodes.product')"
                class="text-caption"
                clearable
                disabled
              />
            </div>
          </th>
          <th class="text-left sm">
            <div class="header" style="align-items: flex-start !important;">
              <VTextField
                v-on:keypress="validationForTitle($event)"
                @paste="validationForTitle($event)"
                dense
                hide-details
                solo
                clearable
                v-model="filterProps.option_name"
                @input="onScanerField"
                :placeholder="$t('nsCodes.option')"
                class="text-caption"
                disabled
              />
            </div>
          </th>
          <th class="text-left md" v-if="!permissions.client">
            <div class="header" style="align-items: flex-start !important;">
              <VTextField
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
                dense
                hide-details
                solo
                :placeholder="$t('nsCodes.range_from')"
                v-model="filterProps.ns_range_from"
                @input="onScanerField"
                clearable
                class="text-caption"
              />
            </div>
          </th>
          <th class="text-left sm">
            <div class="header" style="align-items: flex-start !important;">
              <VTextField
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
                dense
                hide-details
                solo
                :placeholder="$t('nsCodes.range_to')"
                v-model="filterProps.ns_range_to"
                @input="onScanerField"
                clearable
                class="text-caption"
              />
            </div>
          </th>
          <th class="text-left sm">
            <div class="header" style="align-items: flex-start !important;">
              <VTextField
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
                dense
                hide-details
                solo
                @input="onScanerField"
                :placeholder="$t('nsCodes.amount')"
                class="text-caption"
                clearable
                disabled
              />
            </div>
          </th>
          <th class="text-left font-weight-bold sm" style="max-width: 20px">
            <div class="header">
              {{ $t('nsCodes.incoming_shipment')}}
            </div>
          </th>
          <th class="text-left font-weight-bold sm" style="max-width: 20px">
            <div class="header">
              {{ $t('btn.print')}}
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
          <NsCodesRow
            v-for="(ns_code, index) in ns_codes"
            :key="ns_code.uuid"
            :itemsIN="itemsIN"
            :ns_code="ns_code"
            :index="index"
            @toggleRow="toggleRow"
            :class="{ 'active-row': activeRow == ns_code.uuid }"
          />
          <tr>
            <td
              colspan="9"
              v-intersect.quiet="nextPageHandler"
              v-if="!loadMoreDisabled && !loading"
            >
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </td>
          </tr>
          <tr v-if="!ns_codes.length && !loading">
            <td colspan="9">
              <div class="text-center">
                <h3>{{ $t("table.noData") }}</h3>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <CreateNsCodeDialog
      v-if="permissions.can_view_ns_codes_page && createDialog"
      :visible="createDialog"
      @close="createDialog = false"
    />

    <div class="scroll-up__wrapper">
      <v-fab-transition>
        <VBtn
          v-if="isShowUpBtn"
          color="primary"
          small
          absolute
          bottom
          right
          fab
          @click="onScrollToTop"
          style="bottom: 0; right: 30px;"
        >
          <VIcon>mdi-chevron-up</VIcon>
        </VBtn>
      </v-fab-transition>
    </div>
  </VCard>
</template>

<script>
import language from "../../mixins/language";
import depotService from "../../services/request/depot/depotService";
import notifications from "../../mixins/notifications";
import EventBus from "../../events/EventBus";
import loader from "../../mixins/loader";
import user from "../../mixins/user";
import paginationMixin from "../../mixins/paginationMixin";
import {uniqueByUUID, validationForNumbers, validationForTitle} from "@/mixins/helpers";
import SizeUi from "../../mixins/SizeUi";
import NsCodesRow from "@/components/nsCodes/NsCodesRow.vue";
import CreateNsCodeDialog from "@/components/nsCodes/CreateNsCodeDialog.vue";
import _ from "lodash";
import states from "@/constants/states";
import supplyService from "@/services/request/supply/supplyService";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  name: "NsCodesList",
  mixins: [language, notifications, loader, user, paginationMixin, SizeUi],
  components: {
    NsCodesRow,
    CreateNsCodeDialog,
  },
  data: () => ({
    isLoadingFilter: false,
    activeView: "list",
    searchString: "",
    createDialog: false,
    ns_codes: [],
    addresses: [],
    searchLoading: false,
    loadMoreDisabled: false,
    activeRow: "",
    filterProps: {
      state: '',
      name: '',
      option_name: '',
      ns_range_from: "",
      ns_range_to: "",
    },
    activeItem: null,
    page: 1,
    perPageList: [5, 10, 15],
    intervalFetchData: null,
    firstNumber: "",
    secondNumber: "",
    total: 12,
    states: [],
    items: [],
    itemsIN: [],
    totalPages: 0,
    loading: false,
    windowHeight: document.documentElement.clientHeight,
    isShowUpBtn: false,
    loadTime: 0
  }),
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    async getSupplyList() {
      try {
        const newItems = await supplyService.getSupplyList({
          type: "in"
        });
        this.itemsIN.push(...newItems.object)
      } catch (e) {
        this.loadingSupply = false;
      }
    },
    validationForNumbers,
    validationForTitle,
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    onScrollToTop() {
      this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
    },
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    handleScroll(e) {
      if (document.querySelector(".v-data-table__wrapper").scrollTop > 750) {
        this.isShowUpBtn = true;
      } else {
        this.isShowUpBtn = false;
      }
    },
    onApplyFilter(filterItems) {
      this.filterProps = {
        ...filterItems
      };
      this.updateList();
    },
    onScanerField: _.debounce(function() {
      this.updateList();
    }, 500),
    toggleRow(event) {
      this.activeItem = event;
    },
    updateList(updated) {
      this.getSupplyItems(updated);
    },
    async getSupplyItems(updated) {
      try {
        this.loading = true;
        this.loadMoreDisabled = this.ns_codes.length < this.perPage;
        const prefix =  "product_info__";
        const params = [];
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params.uselike = true;
            if (key === "state") {
              params.id_state = this.filterProps.state;
            } else if (key === "name" || key === "option_name") {
              params[prefix + key] = this.filterProps[key];
            } else {
              params[key] = this.filterProps[key];
            }
          }
        });


        if (this.filter) {
          params.uuid_deport = this.filter;
        }

        params.offset = 0;
        params.limit = updated ? this.largeLimit : this.perPage * this.page;
        const items = await depotService.getNsCodesList({
          ...params,
          uuid_deport: this.itemsIN[0].uuid_deport
        });

        this.ns_codes.push(...items);
        this.ns_codes = uniqueByUUID(items);
        this.loadMoreDisabled = items.length < this.perPage;
        this.setLoading(false);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.setLoading(false);
        console.log(e);
      }
    },
    getPaginationParams() {
      return {
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage
      };
    },
    getStatusName(stateId) {
      return states[stateId];
    },
    async prevPage() {
      if (this.page > 1) {
        this.page -= 1;
        await this.emitPageChange();
      }
    },

    nextPageHandler: _.debounce(function(entries, observer, isIntersecting) {
      if(entries[0].isIntersecting){
        console.info("[NS]: Is intersect ns code list");
        this.nextPage();
      }
    }, 100),
    async nextPage() {
      this.page += 1;
      await this.emitPageChange(true);
    },
    async emitPageChange() {
      try {
        this.isLoadingFilter = true;
        this.reorderNumbers();
        const prefix =  "product_info__";
        const params = this.getPaginationParams();

        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            if (key === "state") {
              params.id_state = this.filterProps.state;
            } else if (key === "name" || key === "option_name") {
              params[prefix + key] = this.filterProps[key];
            } else {
              params[key] = this.filterProps[key];
            }
          }
        });

        if (Object.keys(params).length > 2) {
          params.uselike = true;
        }

        const newItems = await depotService.getNsCodesList({
          ...params,
          uuid_deport: this.itemsIN[0].uuid_deport
        });
        //
        // orderDialog(this.loadMoreDisabled);
        this.ns_codes.push(...newItems);
        this.ns_codes = uniqueByUUID(this.ns_codes);
        // this.loadMoreDisabled = this.page > Math.ceil(this.totalPages)
        this.loadMoreDisabled = newItems < this.perPage;
        this.isLoadingFilter = false;
      } catch (e) {
        console.log(e);
      }
      // EventBus.$emit('change-page', params);
    },
    reorderNumbers() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const tempResult = this.page * this.perPage;
      this.secondNumber = tempResult > this.total ? this.total : tempResult;
    },
  },
  async mounted() {
    if (!this.$vuetify.breakpoint.smAndDown) {
      const elem = document.querySelector(".v-data-table__wrapper");
      elem.addEventListener("scroll", this.handleScroll);
    }
   // EventBus.$on("depot-updated", async () => {
      // await this.fetchFirstPage();
     // await this.onSearch(true);
   // });
    EventBus.$on("ns-code-created", async () => {
      // await this.fetchFirstPage();
      this.setLoading(true);
      await this.getSupplyItems();
      this.setLoading(false);
    });
    EventBus.$on("ns-code-updated", async () => {
      await this.getSupplyList();
    });
    this.setLoading(true);
    await this.getSupplyList()
    await this.getSupplyItems();
    this.setLoading(false);
    this.checkLoadTime();
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 500) {
        this.page = this.pageBeforeLimit - 1;
        this.$scrollTop();
        return 500;
      }
      return this.perPage * this.page;
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 150) / this.rowHeight);
    },
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);
      const formattedEvent = `${year}-${month}-${day}`;
      return formattedEvent;
    },
    nextPageDisabled() {
      return this.ns_codes.length < this.perPage;
    },
    getTableHeight() {
      return this.windowHeight - 154 + "px";
    }
  },
  watch: {
    loadMoreDisabled(e) {},
    page(e) {
      if (this.perPage * this.page >= 500 && this.perPage * this.page <= 500 + this.perPage) {
        this.pageBeforeLimit = e;
      }
    },
    async perPage() {
      await this.emitPageChange();
    },
    filterProps: {
      deep: true,
      handler() {
        this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
      }
    }
  }
};
</script>

<style lang="scss"></style>
